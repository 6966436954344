import styles from "./gallerie.module.css"

import ImgWontons from "../images/raviolis-small-gallerie.jpg"
import ImgViandeHachee from "../images/viande-hachee-small-gallerie.jpg"
import ImgTheFraise from "../images/thé-jasmin-fraise-coco-small-gallerie.jpg"
import ImgPiments from "../images/piment-small-gallerie.jpg"
import ImgNouilles from "../images/nouilles-small-gallerie.jpg"
import ImgNouillesBoeuf from "../images/nouilles-2-small-gallerie.jpg"
import ImgBrownSugar from "../images/bubble-tea-brown-sugar-small-gallerie.jpg"
import ImgBanfan from "../images/banfan-small-gallerie.jpg"


export const Gallerie = () => {


  return (
    <section id="gallerie" className={styles.gallerie}>
      <h2 className={styles.gallerie_title}><span>
        Image</span> <br /> gallerie</h2>
      <div className={styles.gallerie_img_container}>
        <img src={ImgWontons} alt="bol wontons" />
        <img src={ImgPiments} alt="piments rouge" />
        <img src={ImgNouilles} alt="nouilles de riz" />
        <img src={ImgBrownSugar} alt="boisson brown sugar" />
        <img src={ImgViandeHachee} alt="viande hachée" />
        <img src={ImgTheFraise} alt="boisson lait fraise" />
        <img src={ImgNouillesBoeuf} alt="nouilles au boeuf" />
        <img src={ImgBanfan} alt="banfan au poulet" />
      </div>
    </section>
  )
}
