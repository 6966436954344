import styles from "./topInfo.module.css"

export const TopInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <p><i className="fa fa-map-marker"></i>22 place Jean Bene, Montpellier</p>
        <a href="tel:+0952600787"><i className="fa fa-phone"></i>09 52 60 07 87</a>
        <p><i className="fa fa-calendar"></i> Mercredi au Dimanche inclus 12h - 14h et 18h30 - 21h30</p>
      </div>
      <ul className={styles.social}>
        <a href="#"><i className="fab fa-facebook"></i></a>
        <a href="#"><i className="fab fa-instagram"></i></a>
      </ul>
    </div>
  )
}
