import { useEffect, useRef, useState } from "react"
import styles from "./navbar.module.css"
import Logo from "../images/logo-main.png"

export const Navbar = () => {
  const [ isTop, setIsTop ] = useState(false)
  const [ isActive, setIsActive ] = useState("#accueil")

  const navClasses = `${styles.navbar} ${isTop ? styles.scrolled_navbar : ""}`

  const handleScroll = () => {
    console.log(window.scrollY)
    window.scrollY > 60 ? setIsTop(true) : setIsTop(false)

    if (window.scrollY < 690) setIsActive("#accueil")
    if (window.scrollY > 695 && window.scrollY < 7380) setIsActive("#menu")
    if (window.scrollY > 7380 && window.scrollY < 8230) setIsActive("#gallerie")
    if (window.scrollY > 8230) setIsActive("#contact")
  }

  const handleClick = e => setIsActive(e.target.hash)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <nav className={navClasses}>
      <img src={Logo} alt="logo du restaurant" />
      <ul>
        <li><a onClick={handleClick} className={isActive === "#accueil" ? styles.active : ""} href="#accueil">Accueil</a></li>
        <li><a onClick={handleClick} className={isActive === "#menu" ? styles.active : ""} href="#menu">Menu</a></li>
        <li><a onClick={handleClick} className={isActive === "#gallerie" ? styles.active : ""} href="#gallerie">Gallerie</a></li>
        <li><a onClick={handleClick} className={isActive === "#contact" ? styles.active : ""} href="#contact">Contact</a></li>
      </ul>
    </nav >
  )
}
