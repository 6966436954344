export const starter = [
  {
    category: "Apéritif 前菜",
    imageName: "salade_boeuf_braisé",
    frenchName: "Salade boeuf braisé aux épices avec coriandre",
    chineseName: "凉拌香菜牛肉",
    spicesInfo: "Niveau pimenté 0-4",
    moreInfo: null,
    moreInfoChinese: null,
    description: "Boeuf tranché, coriandre, épices, sauce soja, gingembre, ail, sésame",
    price: 12,
    isRecommended: false
  },
  {
    category: "Apéritif 前菜",
    imageName: "tofu_du_chef",
    frenchName: "Tofu du chef",
    chineseName: "包浆豆腐",
    spicesInfo: "Niveau pimenté 0-4",
    moreInfo: null,
    moreInfoChinese: null,
    description: "Tofu, sauce du chef",
    price: `6 pour 6 pièces`,
    morePrice: `10 pour 12 pièces`,
    isRecommended: false
  },
  {
    category: "Apéritif 前菜",
    imageName: "salade_concombre",
    frenchName: "Salade de concombres aux piments et poivre du Sichuan",
    chineseName: "炝拌黄瓜",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Concombre, ail, vinaigre, sauce de soja, poivre du Sichuan, piment",
    price: 5,
    isRecommended: false
  },
  {
    category: "Apéritif 前菜",
    // imageName: "pop_frit",
    frenchName: "Pop de poulet frits",
    chineseName: "炸鸡米花",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Poulet frit",
    price: `6.5 pour 8 pièces`,
    isRecommended: false
  },
  {
    category: "Apéritif 前菜",
    // imageName: "raviolis_frits",
    frenchName: "Raviolis frits au poulet",
    chineseName: "炸鸡肉饺",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: `6.5 pour 6 pièces`,
    isRecommended: false
  },
  {
    category: "Apéritif 前菜",
    // imageName: "nems_frits",
    frenchName: "Nems frits aux crevettes",
    chineseName: "炸虾肉春卷",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: `6.5 pour 5 pièces`,
    isRecommended: false
  },
]

export const noodles = [

  {
    // category: "Nouilles froides 凉面",
    imageName: "wontons",
    frenchName: "Soupe aux wontons",
    chineseName: "抄手",
    spicesInfo: "Niveau pimenté 0-4",
    moreInfo: "Possible sans soupe",
    moreInfoChinese: null,
    description: "Porc haché, oeuf, châtaigne d'eau, légumes de saison, sésame, cacahuètes, ail, gingembre, sauce soja, poivre du Sichuan, bouillon de poulet (os et viande), ciboulette",
    price: 14,
    isRecommended: false
  },
  {
    // category: "Nouilles froides 凉面",
    imageName: "nouilles_porc_pois_casses",
    frenchName: "Nouilles au porc haché et aux pois cassés",
    chineseName: "重庆碗杂面",
    spicesInfo: "Niveau pimenté 0-4",
    moreInfo: null,
    moreInfoChinese: null,
    description: "Nouilles de blé, légumes, viande hachée de porc, pois jaune cassés, cacahuètes, ail, gingembre,sauce soja, sésame, poivre du Sichuan, ciboulette, bouillon de poulet",
    price: 14,
    isRecommended: false
  },
  {
    // category: "Nouilles froides 凉面",
    imageName: "te_se_liang_mian",
    frenchName: "Nouilles froides sans soupe",
    chineseName: "重庆特色凉面",
    spicesInfo: "Niveau pimenté 0-4",
    moreInfo: "végétarien",
    moreInfoChinese: null,
    description: "Nouilles de blé fraiches, carottes, pousses de soja, concombre, ail, gingembre, sésame, cacahuètes, sauce soja, vinaigre de riz, poivre du Sichuan, sucre, ciboulette",
    price: 10,
    isRecommended: false
  },
  {
    // category: "Nouilles froides 凉面",
    imageName: "nouilles_froides_ciboulette",
    frenchName: "Nouilles à la sauce ciboulette sans soupe",
    chineseName: "葱油面",
    spicesInfo: "Niveau pimenté 0-4",
    moreInfo: "végétarien",
    moreInfoChinese: null,
    description: "Nouilles de blé, sauce soja, ciboulette",
    price: 10,
    isRecommended: false
  },
  {
    category: "Nouilles 面食",
    imageName: "soupe_nouilles_classiques",
    frenchName: "Soupe aux nouilles classiques",
    chineseName: "面条",
    spicesInfo: null,
    moreInfo: "Possible sans soupe",
    moreInfoChinese: "可以干拌",
    description: "Nouilles de blé, légumes de saison, sésame, cacahuètes, ail, gingembre, sauce soja, poivre du Sichuan, bouillon de poulet (os et viande), ciboulette, coriandre",
    price: 9.5,
    isRecommended: true
  },
  {
    category: "Nouilles 面食",
    imageName: "soupe_nouilles_riz",
    frenchName: "Soupe aux nouilles de riz",
    chineseName: "米粉",
    spicesInfo: null,
    moreInfo: "Possible sans soupe",
    moreInfoChinese: "可以干拌",
    description: "Nouilles de riz, légumes de saison, sésame, cacahuètes, ail, gingembre, sauce soja, poivre du Sichuan, bouillon de poulet (os et viande), ciboulette, coriandre",
    price: 9.5,
    isRecommended: true
  },
  {
    category: "Nouilles 面食",
    imageName: "soupe_nouilles_patate_douce",
    frenchName: "Soupe aux vermicelles de patate douce",
    chineseName: "酸辣粉 ",
    spicesInfo: null,
    moreInfo: "Texture gélatineuse",
    moreInfoChinese: null,
    description: "Nouilles de patate douce, pousse de soja, sésame, cacahuètes, ail, gingembre, sauce soja, vinaigre de riz, poivre du Sichuan, bouillon de poulet(os et viande), ciboulette, coriandre",
    price: 9.5,
    isRecommended: true
  },
]

export const coldNoodles = [
  {
    category: "Complément pour les Nouilles",
    imageName: "crevettes_tomates",
    frenchName: "Crevettes aux tomates",
    chineseName: "番茄虾仁",
    spicesInfo: null,
    moreInfo: "Complément pour les nouilles",
    moreInfoChinese: null,
    description: null,
    price: 5,
    isRecommended: false
  },
  {
    category: "Nouilles 面食",
    imageName: "viande_hachée_porc",
    frenchName: "Viande hachée de porc",
    chineseName: "炸酱",
    spicesInfo: null,
    moreInfo: "Complément pour les nouilles",
    moreInfoChinese: null,
    description: null,
    price: 4,
    isRecommended: false
  },
  {
    category: "Nouilles 面食",
    imageName: "boeuf_braisé",
    frenchName: "Boeuf braisé",
    chineseName: "卤牛肉",
    spicesInfo: null,
    moreInfo: "Complément pour les nouilles",
    moreInfoChinese: null,
    description: null,
    price: 4,
    isRecommended: false
  },
  {
    category: "Nouilles 面食",
    imageName: "tripes_porc",
    frenchName: "Tripes de porc mijotés",
    chineseName: "红烧肥肠",
    spicesInfo: "Niveau pimenté 2",
    moreInfo: "Complément pour les nouilles",
    moreInfoChinese: null,
    description: null,
    price: 4,
    isRecommended: false
  },
  {
    category: "Nouilles 面食",
    imageName: "oeuf_braise",
    frenchName: "oeuf braisé",
    chineseName: "卤鸡蛋",
    spicesInfo: null,
    moreInfo: "Complément pour les nouilles",
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  },

  // {
  //   // category: "Nouilles froides 凉面",
  //   imageName: "wontons",
  //   frenchName: "Soupe aux wontons",
  //   chineseName: "抄手",
  //   spicesInfo: "Niveau pimenté 0-4",
  //   moreInfo: "Possible sans soupe",
  //   moreInfoChinese: null,
  //   description: "Porc haché, oeuf, châtaigne d'eau, légumes de saison, sésame, cacahuètes, ail, gingembre, sauce soja, poivre du Sichuan, bouillon de poulet (os et viande), ciboulette",
  //   price: 13.5,
  //   isRecommended: false
  // },
  // {
  //   // category: "Nouilles froides 凉面",
  //   // imageName: "nouilles_porc_hache",
  //   frenchName: "Nouilles au porc haché et aux pois cassés",
  //   chineseName: "重庆碗杂面",
  //   spicesInfo: "Niveau pimenté 0-4",
  //   moreInfo: null,
  //   moreInfoChinese: null,
  //   description: "Nouilles de blé, légumes, viande hachée de porc, pois jaune cassés, cacahuètes, ail, gingembre,sauce soja, sésame, poivre du Sichuan, ciboulette, bouillon de poulet",
  //   price: 14,
  //   isRecommended: false
  // },
  // {
  //   // category: "Nouilles froides 凉面",
  //   imageName: "nouilles_froides_poulet",
  //   frenchName: "Nouilles froides sans soupe",
  //   chineseName: "重庆特色凉面",
  //   spicesInfo: "Niveau pimenté 0-4",
  //   moreInfo: "végétarien",
  //   moreInfoChinese: null,
  //   description: "Nouilles de blé fraiches, carottes, pousses de soja, concombre, ail, gingembre, sésame, cacahuètes, sauce soja, vinaigre de riz, poivre du Sichuan, sucre, ciboulette",
  //   price: 10,
  //   isRecommended: false
  // },
  // {
  //   // category: "Nouilles froides 凉面",
  //   imageName: "nouilles_froides_ciboulette",
  //   frenchName: "Nouilles à la sauce ciboulette sans soupe",
  //   chineseName: "葱油面",
  //   spicesInfo: "Niveau pimenté 0-4",
  //   moreInfo: "végétarien",
  //   moreInfoChinese: null,
  //   description: "Nouilles de blé, sauce soja, ciboulette",
  //   price: 10,
  //   isRecommended: false
  // },
]

export const rice = [
  {
    category: "Accompagné de riz 盖饭类",
    frenchName: "盖饭 (GAI FAN)",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Style de cuisine qui comprend généralement un bol de riz recouvert de divers ingrédients et assaisonnements. Ces ingrédients peuvent inclure de la viande, des légumes, des fruits de mer, du tofu, etc.. Ce plat est généralement servi dans un grand bol et est l'un des plats préférés de nombreux habitants du Sichuan, que ce soit en tant que plat rapide ou plat familial.",
    price: null,
    isRecommended: false
  },
  {
    category: "Accompagné de riz 盖饭类",
    imageName: "gai_fan_pois",
    frenchName: "Gai fan à la soupe de pois cassés",
    chineseName: "豆汤饭",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Riz nature, pois cassés, bouillon de poulet, pousses de soja, chou vert mariné, ciboulette",
    price: 10,
    isRecommended: false
  },
  {
    category: "Accompagné de riz 盖饭类",
    imageName: "gai_fan_poulet",
    frenchName: "Gai fan au poulet pané",
    chineseName: "炸鸡排饭",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Riz nature, filets de poulet frit, oignion, œuf, algues séchées, chou vert mariné, ciboulette",
    price: 14,
    isRecommended: false
  },
  {
    category: "Accompagné de riz 盖饭类",
    imageName: "gai_fan_crevettes",
    frenchName: "Gai fan aux crevettes avec tomates",
    chineseName: "番茄虾仁饭",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Riz nature, crevettes, tomates, chou vert mariné, ciboulette",
    price: 14,
    isRecommended: false
  },
  {
    category: "Accompagné de riz 饭类",
    // imageName: "bol_riz_nature",
    frenchName: "Bol de riz nature",
    chineseName: "白米饭",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  } ]

export const wontons = [
  {
    category: "Wontons 抄手",
    imageName: "wontons",
    frenchName: "Soupe aux wontons",
    chineseName: "抄手",
    spicesInfo: "Niveau pimenté 0-4",
    moreInfo: "Possible sans soupe",
    moreInfoChinese: null,
    description: "Porc haché, oeuf, châtaigne d'eau, légumes de saison, sésame, cacahuètes, ail, gingembre, sauce soja, poivre du Sichuan, bouillon de poulet (os et viande), ciboulette",
    price: 14,
    isRecommended: false
  },
]

export const dishes = [
  // {
  //   category: "Plats 菜",
  //   imageName: "travers_porc_caramel",
  //   frenchName: "Travers de proc au caramel",
  //   chineseName: "糖醋排骨",
  //   spicesInfo: "Niveau pimenté 0-4",
  //   moreInfo: null,
  //   moreInfoChinese: null,
  //   description: "Travers de proc, sauce soja, vinaigre de riz, sucre, sésame",
  //   price: 16.5,
  //   isRecommended: false
  // } 
]

export const sticks = [
  // {
  //   category: "Brochettes frites 炸串",
  //   imageName: "brioche_chinoise",
  //   frenchName: "Brioche chinoise",
  //   chineseName: "馒头",
  //   spicesInfo: null,
  //   moreInfo: "Possible avec lait concentré",
  //   moreInfoChinese: null,
  //   description: null,
  //   price: 3,
  //   isRecommended: false
  // } 
]

export const aside = [
  {
    category: "Suppléments 加料",
    imageName: "poudre_pimentée",
    frenchName: "Poudre pimentée",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 1,
    isRecommended: false
  },
  {
    category: "Suppléments 加料",
    imageName: "oeuf_braise",
    frenchName: "Oeuf braisé",
    chineseName: "卤鸡蛋",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  }
]

export const desert = [
  {
    category: "Dessert 甜点",
    imageName: "tang_zhi_gan_lu",
    frenchName: "Yang Zhi Gan Lu",
    chineseName: "杨枝甘露",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Mangue, pamplemousse, lait, lait de coco, perles agar-agar",
    price: 6,
    isRecommended: false
  },
  {
    category: "Dessert 甜点",
    imageName: "dou_liang",
    frenchName: "Fleur de tofu au riz gluant fermenté",
    chineseName: "酒酿冰豆花",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "Tofu soyeux, riz gluant fermenté (très légèrement alcoolisé), lait, goji",
    price: 6,
    isRecommended: false
  }
]

export const MilkBubbleTea = [
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "bubble_tea_classique",
    frenchName: "Thé noir au lait avec perles de tapioca",
    chineseName: "招牌原味奶茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6,
    isRecommended: true,
    hotDrink: true
  },
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "bubble_tea_matcha",
    frenchName: "Thé vert jasmin au lait avec perles de tapioca",
    chineseName: "茉香奶绿",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6,
    isRecommended: true,
    hotDrink: true
  },
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "bubble_tea_brown_sugar",
    frenchName: "Lait au sucre roux avec perles de tapioca",
    chineseName: "黑糖鲜奶",
    spicesInfo: null,
    moreInfo: "La douceur de cette boisson vient uniquement de sucre roux",
    moreInfoChinese: "该饮品的甜度只来源于黑糖的量",
    description: null,
    price: 6,
    isRecommended: true,
    hotDrink: true
  },
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "taro_thé_lait",
    frenchName: "Lait aux sucre roux et matcha avec perles de tapioca",
    chineseName: "抹茶黑糖鲜奶",
    spicesInfo: null,
    moreInfo: "La douceur de cette boisson vient uniquement de sucre roux",
    moreInfoChinese: "该饮品的甜度只来源于黑糖的量",
    description: null,
    price: 6.5,
    isRecommended: true,
    hotDrink: true
  },
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "taro_thé_lait",
    frenchName: "Matcha thé vert jasmin au lait avec perles de tapioca",
    chineseName: "抹茶牛乳茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6.5,
    isRecommended: true,
    hotDrink: true
  },
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "taro_thé_lait",
    frenchName: "Mangue avec thé vert jasmin au lait",
    chineseName: "鲜芒清乳茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7,
    isRecommended: true,
    hotDrink: true
  },
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "taro_thé_lait",
    frenchName: "Lait de coco matcha avec perles de tapioca",
    chineseName: "珍珠椰椰抹茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6.5,
    isRecommended: true,
    hotDrink: false
  },
  {
    category: "Boissons au lait 奶茶系列",
    // imageName: "taro_thé_lait",
    frenchName: "Thé vert jasmin au lait de coco et citron avec perles agar-agar",
    chineseName: "柠檬椰香啵啵白月光",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6.5,
    isRecommended: true,
    hotDrink: false
  },
]

export const creamBubbleTea = [
  {
    category: "Thés avec crème fouettée 奶盖茶系列",
    // imageName: "thé_noir_crème_fouettée",
    frenchName: "Thé noir recouvert de crème fouettée",
    chineseName: "芝士奶盖红茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6,
    isRecommended: true,
    hotDrink: false
  },
  {
    category: "Thé avec crème fouettée 奶盖茶系列",
    // imageName: "thé_noir_crème_fouettée",
    frenchName: "Thé vert jasmin recouvert de crème fouettée",
    chineseName: "芝士奶盖茉香绿茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6,
    isRecommended: true,
    hotDrink: false
  },
  {
    category: "Thé avec crème fouettée 奶盖茶系列",
    // imageName: "thé_noir_crème_fouettée",
    frenchName: "Thé noir au lait recouvert de crème fouettée",
    chineseName: "芝士厚乳奶茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7,
    isRecommended: true,
    hotDrink: false
  },
  {
    category: "Thé avec crème fouettée 奶盖茶系列",
    // imageName: "thé_noir_crème_fouettée",
    frenchName: "Thé vert jasmin au lait recouvert de crème fouettée",
    chineseName: "茉香厚乳奶茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7,
    isRecommended: true,
    hotDrink: false
  },
  {
    category: "Thé avec crème fouettée 奶盖茶系列",
    // imageName: "thé_noir_crème_fouettée",
    frenchName: "Thé vert jasmin à la mangue recouvert de crème fouettée",
    chineseName: "芝士奶盖芒芒",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7.5,
    isRecommended: true,
    hotDrink: false
  },
  {
    category: "Thé avec crème fouettée 奶盖茶系列",
    // imageName: "thé_noir_crème_fouettée",
    frenchName: "Lait de vache et de coco à la mangue recouvert de crème fouettée",
    chineseName: "芝士生椰芒芒",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7.5,
    isRecommended: true,
    hotDrink: false
  },

]

export const coffeeBased = [
  {
    category: "Boissons à base de café 咖啡类",
    // imageName: "mojito_classique",
    frenchName: "Lait de coco au café",
    chineseName: "生椰拿铁",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6,
    isRecommended: false
  },
  {
    category: "Boissons à base de café",
    // imageName: "mojito_classique",
    frenchName: "Rose latté",
    chineseName: "玫瑰厚乳拿铁",
    spicesInfo: null,
    moreInfo: "Pas de choix au niveau du sucre en option",
    moreInfoChinese: "不可选甜度",
    description: null,
    price: 6,
    isRecommended: false
  },
  {
    category: "Boissons à base de café",
    // imageName: "mojito_classique",
    frenchName: "Lait de coco au thé vert jasmin avec café",
    chineseName: "茉莉椰椰拿铁",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6.5,
    isRecommended: false
  },
  {
    category: "Boissons à base de café",
    // imageName: "mojito_classique",
    frenchName: "Lait de coco au café avec matcha",
    chineseName: "绿椰仙棕",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 6.5,
    isRecommended: false
  },

]

export const freshFruitsTea = [
  {
    category: "Thés aux fruits frais 水果茶系列",
    // imageName: "thé_jasmin_fraises_coco",
    frenchName: "Thé noir au citron",
    chineseName: "暴打柠檬红茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 5.5,
    isRecommended: false
  },
  {
    category: "Thé aux fruits frais 水果茶系列",
    // imageName: "thé_jasmin_fraises_coco",
    frenchName: "Thé vert jasmin au citron",
    chineseName: "暴打柠檬绿茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 5.5,
    isRecommended: false
  },
  {
    category: "Thé aux fruits frais 水果茶系列",
    // imageName: "thé_jasmin_fraises_coco",
    frenchName: "Thé vert jasmin aux lichis et citron avec perles agar-agar",
    chineseName: "荔枝冰柠啵啵茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7,
    isRecommended: false
  },
  {
    category: "Thé aux fruits frais 水果茶系列",
    // imageName: "thé_jasmin_fraises_coco",
    frenchName: "Thé vert aux fruits de la passion et citron avec perles agar-agar",
    chineseName: "柠檬百香果啵啵茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7,
    isRecommended: false
  },
  {
    category: "Thé aux fruits frais 水果茶系列",
    // imageName: "thé_jasmin_fraises_coco",
    frenchName: "Thé vert à la mangue et fruits de la passion avec perles agar-agar",
    chineseName: "芒芒百香果啵啵茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 7,
    isRecommended: false
  },
  {
    category: "Thé aux fruits frais 水果茶系列",
    // imageName: "thé_jasmin_fraises_coco",
    frenchName: "Thé aux fruits ( ananas, mangue, fruits de la passion, citron vert et fruits de saison) ",
    chineseName: "满杯水果茶",
    spicesInfo: null,
    moreInfo: "Pas de choix au niveau du sucre en option",
    moreInfoChinese: "不可选甜度",
    description: null,
    price: 7.5,
    isRecommended: false
  },

]

export const soda = [
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Coca",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Evian",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Jus de litchis",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2.5,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Orangina",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Perrier",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2.5,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Jus de fruit de la pasion",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2.5,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Ice tea",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Jia duo bao",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: "thé aux plantes",
    price: 2.5,
    isRecommended: false
  },
  {
    category: "Boissons classiques 经典饮品",
    frenchName: "Jus de mangue",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2.5,
    isRecommended: false
  } ]

export const beers = [
  {
    category: "Bières 啤酒",
    frenchName: "Tsingtao",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 3.5,
    isRecommended: false
  },
  {
    category: "Bières 啤酒",
    frenchName: "Corona",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 3.5,
    isRecommended: false
  },
  {
    category: "Bières 啤酒",
    frenchName: "Heineken",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 3.5,
    isRecommended: false
  },
  {
    frenchName: "Desperados",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 3.5,
    isRecommended: false
  } ]

export const coffee = [
  {
    category: "Café et Thés 咖啡 和 纯茶类",
    frenchName: "Espresso",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  },
  {
    category: "Café 咖啡",
    frenchName: "Café allongé",
    chineseName: null,
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 2,
    isRecommended: false
  },
  {
    category: "Café 咖啡",
    frenchName: "Thé noir",
    chineseName: "纯品红茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 4.5,
    isRecommended: false,
    hotDrink: true
  },
  {
    category: "Café 咖啡",
    frenchName: "Thé vert au jasmin",
    chineseName: "茉香绿茶",
    spicesInfo: null,
    moreInfo: null,
    moreInfoChinese: null,
    description: null,
    price: 4.5,
    isRecommended: false,
    hotDrink: true
  },
]