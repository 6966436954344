import { MenuItem } from "./MenuItem"
import "./menuList.css"

export const MenuList = ({ title, obj, display, main }) => {

  const supplBoissonObj = [
    {
      frenchName: "Perles de tapioca",
      chineseName: "珍珠 ",
      price: 1
    },
    {
      frenchName: "Perles agar-agar",
      chineseName: "脆啵啵",
      price: 1
    },
    {
      frenchName: "Crème fouettée",
      chineseName: "奶盖",
      price: 1.5
    },
    {
      frenchName: "Taux de sucre désiré",
      description: "0% 30% 50% 70% 100% 150%"
    },
    {
      frenchName: "Quantité de glaçons désirée",
      description: "sans / un peu / normal / plus"
    },
    {
      frenchName: "Nos boissons marquées d'une étoile peuvent être préparées chaudes"
    }
  ]


  return (
    <>
      {obj &&
        <>
          <h3 className={main ? "menu_list_title" : "menu_list_secondaryTitle"}>{title}</h3>
          <div className={`menu_list_container ${display}`}>
            {obj.map(item => < MenuItem obj={item} key={Math.random() * 100} />)}
          </div>
        </>
      }
      {!obj &&
        <>
          <h3 className="menu_list_title">{title}</h3>
          <h4 className="menu_list_secondaryTitle">Suppléments boisson 加料</h4>
          <div className={`menu_list_container ${display}`}>
            {supplBoissonObj.map(item => < MenuItem obj={item} key={Math.random() * 100} />)}
          </div>
        </>}
    </>
  )
}
