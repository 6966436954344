import { MenuList } from "../components/MenuList"
import styles from "./menu.module.css"
import { aside, beers, coffee, coldNoodles, creamBubbleTea, desert, dishes, freshFruitsTea, MilkBubbleTea, noodles, rice, soda, starter, sticks, coffeeBased, wontons } from "../data-simplified"

export const Menu = () => {
  return (
    <section id="menu" className={styles.menu} >
      <h2 className={styles.menu_title}>Menu</h2>
      <MenuList title={starter[ 0 ].category} main={true} display="grid-2" obj={starter} />
      <MenuList title={noodles[ 0 ].category} main={true} display="grid-2" obj={noodles} />
      <MenuList title={coldNoodles[ 0 ].category} main={true} display="grid-2" obj={coldNoodles} />
      <MenuList title={rice[ 0 ].category} main={true} display="flex" obj={rice} />
      {/* <MenuList title={wontons[ 0 ].category} main={true} display="flex" obj={wontons} /> */}
      {/* <MenuList title={dishes[ 0 ].category} main={true} display="flex" obj={dishes} /> */}
      {/* <MenuList title={sticks[ 0 ].category} main={true} display="grid-2" obj={sticks} /> */}
      <MenuList title={aside[ 0 ].category} main={false} display="grid-2" obj={aside} />
      <MenuList title={desert[ 0 ].category} main={true} display="grid-2" obj={desert} />
      <MenuList title="Boissons bubble tea 饮品" main={true} display="grid-2" />
      <MenuList title={MilkBubbleTea[ 0 ].category} display="grid-2" obj={MilkBubbleTea} main={false} />
      <MenuList title={freshFruitsTea[ 0 ].category} display="grid-2" obj={freshFruitsTea} main={false} />
      <MenuList title={creamBubbleTea[ 0 ].category} display="grid-2" obj={creamBubbleTea} main={false} />
      <MenuList title={coffeeBased[ 0 ].category} display="grid-2" obj={coffeeBased} main={false} />
      <MenuList title={soda[ 0 ].category} display="grid-3" obj={soda} main={true} />
      <MenuList title={beers[ 0 ].category} display="grid-3" obj={beers} main={true} />
      <MenuList title={coffee[ 0 ].category} display="grid-3" obj={coffee} main={true} />
    </section>
  )
}

