import { useRef } from "react"
import styles from "./hero.module.css"

export const Hero = () => {

  return (
    <section id="accueil" className={styles.hero}  >
      <main className={styles.hero_paragraph}>
        <h2>Bienvenue au</h2>
        <h1>Sensation De Sichuan</h1>
        <p>Recettes de grand-mère</p>
        <p>Appelez-nous au 09 52 60 07 87</p>
        <p>Ouvert du Mercredi au Dimanche inclus</p>
        <p>Horaires : 12h - 14h et 18h30 - 21h30</p>
      </main>
      <i className="fa-sharp fa-solid fa-arrow-down"></i>
    </section>
  )
}
