import styles from "./menuItem.module.css"

export const MenuItem = ({ obj }) => {
  const { frenchName, chineseName, spicesInfo, moreInfo, moreInfoChinese, description, price, isRecommended, imageName, morePrice, hotDrink } = obj

  return (
    <div className={styles.item_container}>
      {imageName && <div className={styles.item_img}>
        <img src={require(`../images/${imageName}.jpg`)} alt="" />
      </div>}
      <div className={styles.item_description}>
        <h3>{frenchName} </h3>
        <h3>{chineseName} </h3>
        {spicesInfo && <p>{spicesInfo} </p>}
        {moreInfo && <p className={styles.item_moreInfo}>{moreInfo} </p>}
        {moreInfoChinese && <p>{moreInfoChinese} </p>}
        {description && <p> {description}</p>}
      </div>
      <div className={styles.item_price}>
        {price && <p>€ {price}</p>}
        {morePrice && <p>€ {morePrice}</p>}
        {/* {isRecommended && <p></p>} */}
        {hotDrink && <p>&#9733;</p>}
      </div>
    </div>
  )
}
