import styles from "./App.css"
import { Hero } from './sections/Hero';
import { Navbar } from './components/Navbar';
import { TopInfo } from './components/TopInfo';
import { Menu } from "./sections/Menu";
import { Gallerie } from "./sections/Gallerie";
import { Contact } from "./sections/Contact";
import { Footer } from "./sections/Footer";

function App() {

  return (
    <div className={styles.app}>
      <TopInfo />
      <Navbar />
      <Hero />
      <Menu />
      <Gallerie />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
