import styles from "./footer.module.css"


export const Footer = () => {
  return (
    <section className={styles.footer_container}>
      <div className={styles.block}>
        <h3>Création et hébergement</h3>
        <p>Sité créé et administré par <br /> <a href="https://vicente-digital.com/" target="_blank">Vicente Digital</a></p>
        <p>Sité hébergé par <br />  <a href="https://www.netlify.com/" target="_blank">Netlify</a></p>
      </div>
      <div className={styles.block}>
        <h3>Société</h3>
        <p>LGL Food<br /> SAS au capital de 3000 €</p>
        <p><i className="fa fa-map-marker"></i> 22 place Jean Bene, Montpellier <br /> Occitanie, France</p>
      </div>
      <div className={styles.block}>
        <h3>Contact Info</h3>
        <p><i className="fa fa-calendar"></i> Mercredi au Dimanche inclus 12h - 14h et 18h30 - 21h30</p>
        <p><a href="tel:+0952600787"><i className="fa fa-phone"></i> 09 52 60 07 87</a></p>
        <p><i className="fa-solid fa-envelope"></i> <a href="mailto:lglfood34@gmail.com"> lglfood34@gmail.com</a></p>
      </div>
    </section>
  )
}
