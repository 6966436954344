import Map from "../images/map.png"
import styles from "./contact.module.css"

export const Contact = () => {

  return (
    <section id="contact" className={styles.contact} >
      <h2 className={styles.contact_title}>Contact</h2>
      <div className="localisation_container">
        <img src={Map} alt="localisation restaurant" className={styles.map} />
        <aside className={styles.contact_aside}>
          <h2>Retrouvez-nous</h2>
          <h3>Sensation du Sichuan</h3>
          <p>22 place Jean Bene, Montpellier, Occitanie, France</p>
          <p>Mercredi au Dimanche inclus 12h à 14h et de 18h30 à 22h</p>
          <p>09 52 60 07 87</p>
        </aside>
      </div>
    </section>
  )
}
